import Vue from 'vue';
import VueI18n from 'vue-i18n';

// eslint-disable-next-line import/no-cycle
import store from '@/store';


// languages
import hu from './languages/hu.json';
import de from './languages/de.json';
import en from './languages/en.json';
import pl from './languages/pl.json';
import ru from './languages/ru.json';
import ro from './languages/ro.json';
import ua from './languages/ua.json';
import cz from './languages/cz.json';
import sk from './languages/sk.json';

Vue.use(VueI18n);

const locale = store.getters.langCode || process.env.VUE_APP_I18N_FALLBACK_LANG_CODE;

const messages = {
  hu,
  de,
  en,
  pl,
  ru,
  ro,
  ua,
  cz,
  sk,
};

const i18n = new VueI18n({
  locale,
  messages,
});

export default i18n;
