<!-- eslint-disable -->
<template>
  <div class="icon-cart">
    <svg :fill="color" xmlns="http://www.w3.org/2000/svg" width="21.68" height="22.41" viewBox="0 0 21.68 22.41">
      <g id="Group_2333" data-name="Group 2333" transform="translate(0 0)">
        <circle id="Ellipse_21" data-name="Ellipse 21" cx="2.649" cy="2.649" r="2.649" transform="translate(5.974 17.111)"/>
        <path id="Path_4279" data-name="Path 4279" d="M269.146,324.1h0a2.649,2.649,0,1,0,2.649,2.649A2.649,2.649,0,0,0,269.146,324.1Z" transform="translate(-252.792 -306.987)"/>
        <path id="Path_4280" data-name="Path 4280" d="M27.782,14.408H13.968L13.7,12.84H25.052a.811.811,0,0,0,.757-.514l2.7-6.812a.811.811,0,0,0-.757-1.108H12.292L11.644.675A.811.811,0,0,0,10.833,0H7.724a.811.811,0,1,0,0,1.622h2.433l2.325,13.732a.811.811,0,0,0,.811.676H27.782a.811.811,0,0,0,0-1.622Z" transform="translate(-6.913 0.001)"/>
      </g>
    </svg>
  </div>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconCart',
  props: {
    color: {
      type: String,
      required: false,
      default: '#000',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>

</style>
