import axios from 'axios';
import moment from 'moment-timezone';
import { globalErrorHandler } from '@/components/shared/globalErrorHandler';

import store from '@/store';
import i18n from '@/lang/i18n';

const apiURL = process.env.VUE_APP_API_URL;

function fetchData(endpoint, requestType, requestBody) {
  const token = store.state.authToken;
  const timeZone = moment.tz.guess();

  let config = '';
  const language = i18n.locale;

  if (token === undefined) {
    config = {
      headers:
        {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Accept-Language': language,
          'Time-Zone': timeZone,
        },
    };
  } else if (requestBody && requestBody.generatePdf) {
    config = {
      headers:
      {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept-Language': language,
        'x-api-key': token,
        'Time-Zone': timeZone,
      },
    };
  } else {
    config = {
      headers:
      {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept-Language': language,
        'x-api-key': token,
        'Time-Zone': timeZone,
      },
    };
  }

  if (requestBody && requestBody.generatePdf) {
    const pdfAxiosConfig = {
      method: requestType, url: endpoint, headers: config.headers, responseType: 'arraybuffer',
    };
    return axios(pdfAxiosConfig)
      .then((resp) => resp)
      .catch((err) => globalErrorHandler(err));
  }

  if (requestType.toLowerCase() === 'post' || requestType.toLowerCase() === 'get'
    || requestType.toLowerCase() === 'put' || requestType.toLowerCase() === 'delete'
    || requestType.toLowerCase() === 'patch'
  ) {
    const axiosConfig = { method: requestType, url: endpoint, headers: config.headers };
    if (requestType.toLowerCase() === 'get' || requestType.toLowerCase() === 'delete') {
      if (requestBody) {
        axiosConfig.data = requestBody;
      }
      return axios(axiosConfig)
        .then((resp) => resp)
        .catch((err) => globalErrorHandler(err));
    } if (requestType.toLowerCase() === 'post') {
      axiosConfig.data = requestBody;
      if (Object.prototype.hasOwnProperty.call(requestBody, 'hash')) {
        return axios(axiosConfig)
          .then((response) => response)
          .catch((err) => globalErrorHandler(err));
      }
      return axios(axiosConfig)
        .then((response) => response)
        .catch((err) => globalErrorHandler(err));
    } if (requestType.toLowerCase() === 'patch' || requestType.toLowerCase() === 'put') {
      axiosConfig.data = requestBody;
      return axios(axiosConfig)
        .then((response) => response)
        .catch((err) => globalErrorHandler(err));
    }
  } else if (requestType.toLowerCase() === 'file') {
    const formData = new FormData();
    formData.append('file', requestBody);
    config.headers['Content-Type'] = 'multipart/form-data';
    const axiosConfig = { method: 'post', url: endpoint, headers: config.headers };
    axiosConfig.data = formData;
    return axios(axiosConfig)
      .then((response) => response)
      .catch((err) => globalErrorHandler(err));
  }
  throw new Error('Unknown HTTP method');
}

export { fetchData, apiURL };
