import EventBus from '@/components/shared/eventbus';

export const globalErrorTypes = [
  'SERVICE_UNAVAILABLE',
];

// const globalErrorTexts = {};

export const globalErrorHandler = (error) => {
  const errorObject = error.response.data;

  if (globalErrorTypes.includes(errorObject.type)) {
    EventBus.servicesDown();
    return Promise.reject(error);
  }
  return Promise.reject(error);
};
