<template>
  <div id="app">
   <template v-if="!withoutLayoutSections">
      <div v-if="isInSandboxMode" class="sandbox-mode">{{ $t('general.alerts.sandboxMode') }}</div>
      <main-top-navbar />
      <div class="page-wrapper">
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <router-view />
        </transition>
      </div>
      <footer-section />
    </template>
    <template v-else>
      <router-view />
    </template>

    <transition
      mode="in-out"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
      leave-active-class="animate__animated animate__fadeOut animate__faster"
    >
      <modal
        v-if="modalComponent"
        :component="modalComponent"
        :key="modalKey"
      ></modal>
    </transition>
  </div>
</template>

<script>
import hu from 'moment/locale/hu';
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/shared/mixins/modal';

import MainTopNavbar from '@/components/shared/elements/navbars/MainTopNavbar';
import FooterSection from '@/components/shared/modules/sections/FooterSection';

const SimpleModal = () => import('@/components/shared/modules/modals/SimpleModal');

export default {
  name: 'App',
  components: {
    MainTopNavbar,
    FooterSection,
  },
  data: () => ({
    withoutLayoutSections: true,
    servicesDown: {
      redirectsNumber: 0,
      from: undefined,
    },
  }),
  mixins: [Modal],
  methods: {
    ...mapActions({
      getLoggedInUserProfile: 'users/getLoggedInUserProfile',
      getCart: 'cart/getCart',
      getServicesStatus: 'settings/getServicesStatus',
      saveSelectedDate: 'cart/saveSelectedDate',
    }),
    showFacilityWarningModal() {
      this.$eventBus.showModal({
        bind: {
          is: SimpleModal,
          titleText: this.$t('general.alerts.extraTicketWarning.title'),
          descriptionText: this.$t('general.alerts.extraTicketWarning.description'),
          primaryBtnText: this.$t('general.alerts.extraTicketWarning.btnText'),
          modalTitleStyle: { margin: '20px 0 10px' },
          closeable: true,
          maxWidth: 500,
          hasLighterBackground: true,
        },
        on: {
          primaryButtonClicked: () => {
            this.$eventBus.closeModal();
          },
        },
      });
    },
  },
  created() {
    this.registerModals();

    if (store.getters.authToken !== undefined) {
      this.getLoggedInUserProfile();
    }

    if (store.getters.cartId !== undefined) {
      this.getCart(store.getters.cartId);
    }

    this.getServicesStatus();

    this.$moment.locale(this.$i18n.locale, hu);

    // save today's date to store
    const today = this.$moment(this.selectedDateFromStore).format('YYYY-MM-DD');
    this.saveSelectedDate(today);
  },
  computed: {
    ...mapGetters({
      currentUser: 'users/getLoggedInUserProfile',
    }),
    localServicesStatus() {
      return store.getters.servicesStatus;
    },
    authToken() {
      return store.getters.authToken;
    },
    isInSandboxMode() {
      return process.env.VUE_APP_IS_IN_SANDBOX_MODE === 'true';
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    '$route'(to, from) {
      if (this.$route.name === 'ServicesDownScreen' && this.localServicesStatus) {
        this.$router.push('/');
      } else if (this.$route.name !== 'ServicesDownScreen' && this.$route.name !== 'PdfScreen') {
        this.getServicesStatus().then(() => {
          if (!this.localServicesStatus) {
            this.servicesDown.from = from.path === '/services/down' ? to.path : from.path;
            this.$router.push('/services/down');
          }
        });
      }

      if (this.$route.meta.withoutLayoutSections) {
        this.withoutLayoutSections = true;
      } else {
        this.withoutLayoutSections = false;
      }
    },
    localServicesStatus(val) {
      if (val !== undefined && !val) {
        this.servicesDown.from = this.$route.path;
        this.$router.push('/services/down');
      } else {
        this.servicesDown.redirectsNumber = 0;
        this.$router.push(this.servicesDown.from);
      }
    },
    authToken() {
      if (store.getters.authToken !== undefined
        && store.getters.cartId === undefined
        && this.currentUser.cart !== undefined
      ) {
        this.getCart(this.currentUser.cart);
      }
    },
  },
  mounted() {
    this.$eventBus.onServicesDown(this, () => {
      this.servicesDown.redirectsNumber += 1;

      if (this.servicesDown.redirectsNumber === 1) {
        this.getServicesStatus();
      }
    });

    this.$eventBus.onShowFacilityWarningModal(this, () => {
      this.showFacilityWarningModal();
    });
  },
};
</script>

<style lang="scss">
@import '@/assets/stylesheets/_globals.scss';

$navbarMobileHeight: 77px;

#app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  min-height: 100vh;

  @media screen and (max-width: $breakpointDownMd) {
    padding-top: $navbarMobileHeight;
    grid-template-rows: 1fr auto;
  }
}

.sandbox-mode {
  background-color: yellow;
  font-weight: 500;
  color: black;
  text-align: center;
  padding: 5px 0;
  width: 100%;
  z-index: z('sandbox');
  position: relative;
}
</style>
