<!-- eslint-disable -->
<template>
  <div class="icon-profile">
    <svg xmlns="http://www.w3.org/2000/svg" width="23.714" height="28.406" viewBox="0 0 23.714 28.406">
      <g id="user" transform="translate(0.045 0.045)">
        <path id="Path_4281" data-name="Path 4281" d="M14.639,13.64a6.6,6.6,0,0,0,4.822-2,6.6,6.6,0,0,0,2-4.822,6.6,6.6,0,0,0-2-4.822,6.6,6.6,0,0,0-4.822-2A6.6,6.6,0,0,0,9.817,2a6.6,6.6,0,0,0-2,4.822,6.6,6.6,0,0,0,2,4.822,6.6,6.6,0,0,0,4.822,2Zm0,0" transform="translate(-3.006)" :fill="color" stroke="#707070" stroke-width="0.09"/>
        <path id="Path_4282" data-name="Path 4282" d="M23.567,30.315a16.846,16.846,0,0,0-.229-1.789,14.1,14.1,0,0,0-.44-1.8,8.885,8.885,0,0,0-.739-1.678A6.331,6.331,0,0,0,21.043,23.6a4.917,4.917,0,0,0-1.6-1.006,5.536,5.536,0,0,0-2.045-.37,2.076,2.076,0,0,0-1.108.47c-.332.217-.721.467-1.155.744a6.617,6.617,0,0,1-1.494.658,5.8,5.8,0,0,1-3.655,0,6.6,6.6,0,0,1-1.493-.658c-.43-.275-.819-.525-1.156-.745a2.073,2.073,0,0,0-1.108-.47,5.529,5.529,0,0,0-2.045.37A4.913,4.913,0,0,0,2.58,23.6,6.332,6.332,0,0,0,1.465,25.05a8.9,8.9,0,0,0-.739,1.678,14.13,14.13,0,0,0-.44,1.8,16.786,16.786,0,0,0-.229,1.79C.019,30.858,0,31.42,0,31.988a4.7,4.7,0,0,0,1.4,3.557,5.027,5.027,0,0,0,3.6,1.313H18.629a5.026,5.026,0,0,0,3.6-1.313,4.7,4.7,0,0,0,1.4-3.557c0-.571-.019-1.133-.057-1.673Zm0,0" transform="translate(0 -8.542)" :fill="color" stroke="#707070" stroke-width="0.09"/>
      </g>
  </svg>
  </div>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconProfile',
  props: {
    color: {
      type: String,
      required: false,
      default: '#000',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
