import api from '@/api/users';
// import store from '@/store';

const register = (context, registerData) => new Promise((resolve, reject) => {
  api.register(registerData)
    .then((response) => {
      const authToken = response.data.apiKey[0].key;
      context.commit('SAVE_USER_PROFILE', response.data);
      context.commit('storeToken', authToken, { root: true });
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const login = (context, loginData) => new Promise((resolve, reject) => {
  api.login(loginData)
    .then((response) => {
      const authToken = response.data.apiKey[0].key;
      context.commit('SAVE_USER_PROFILE', response.data);
      context.commit('storeToken', authToken, { root: true });
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getLoggedInUserProfile = (context) => new Promise((resolve, reject) => {
  api.getLoggedInUserProfile()
    .then((response) => {
      context.commit('SAVE_USER_PROFILE', response.data);
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const logout = (context) => new Promise(((resolve) => {
  context.commit('storeToken', undefined, { root: true });
  resolve(true);
  context.commit('SAVE_USER_PROFILE', undefined);
  context.commit('users/DESTROY', undefined, { root: true });
}));

const saveLoginError = (context, errorText) => new Promise(((resolve) => {
  context.commit('SAVE_LOGIN_ERROR', errorText);
}));

const requestPasswordReset = (context, email) => new Promise((resolve, reject) => {
  api.requestPasswordReset(email)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const resetPassword = (context, requestObj) => new Promise((resolve, reject) => {
  api.resetPassword(requestObj)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const updateUserProfile = (context, requestObj) => new Promise((resolve, reject) => {
  api.updateUserProfile(requestObj)
    .then((response) => {
      resolve(response);
      context.commit('SAVE_USER_PROFILE', response.data);
    })
    .catch((error) => {
      reject(error);
    });
});

const deleteUserProfile = (context) => new Promise((resolve, reject) => {
  api.deleteUserProfile()
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getPrevPurchases = (context, page) => new Promise((resolve, reject) => {
  api.getPrevPurchases(page)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
});


export default {
  register,
  login,
  getLoggedInUserProfile,
  logout,
  saveLoginError,
  requestPasswordReset,
  resetPassword,
  updateUserProfile,
  deleteUserProfile,
  getPrevPurchases,
};
