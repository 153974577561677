import { fetchData, apiURL } from './api_helper';

const checkSimpleResponse = (requestObj) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/simple/back`;

  fetchData(endpointURL, 'POST', requestObj).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const checkOrderStatus = (requestObj) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/simple/checkOrderStatus`;

  fetchData(endpointURL, 'POST', requestObj).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

export default {
  checkSimpleResponse,
  checkOrderStatus,
};
