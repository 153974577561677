import api from '@/api/cikks';

const getCikks = (context, { cikkcsoportId, selectedDate }) => new Promise((resolve, reject) => {
  api.getCikks(cikkcsoportId, selectedDate)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export default {
  getCikks,
};
