<template>
  <div class="dropdown-list" :class="{ 'visible': isVisible }"
  :style="`--width: ${width ? width + 'px' : '100%'}`">
    <ul v-if="!hasDefaultSlot">
      <li class="dropdown-item" v-for="(item, i) in items" :key="i"
        @click="handleItemClick(item.key)">
          <span>{{ item.text }}</span>
      </li>
    </ul>
    <ul v-else>
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DropdownList',
  props: {
    items: {
      type: Array,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    width: {
      type: Number,
      required: false,
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
  },
  methods: {
    handleItemClick(key) {
      this.$emit('clicked', key);
    },
  },
};
</script>

<style lang='scss' scoped>
$borderHeight: 0.5px;
$marginFromParentSides: 25px;

/*
  !! important: parent element has to be relative positioned
*/

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: var(--width);
  box-shadow: $dropdownBoxShadow;
  max-height: 0;
  overflow: hidden;
  background-color: transparent;
  border-radius: $globalBorderRadius;
  transition: padding 0.8s ease-out, max-height 0.3s ease-out;

  &.visible {
    max-height: 1000px;
    transition: padding 0.3s ease-in-out, max-height 0.8s ease-in-out;
  }

  ul {
    padding: 15px 0;
    margin: 0;
    list-style-type: none;
    border-radius: $globalBorderRadius;
    border: $borderHeight solid rgba($globalBorderColor, 0.5);
    background-color: $globalBackgroundColor;
  }
}

.dropdown-item {
  text-align: left;
  font-size: 12px;
  transition: $transitionBase;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  padding: 3px 0 3px 24px;
  margin: 3px 0;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: $primaryBlue;
    color: $white;
  }
}
</style>
