<template>
  <div id='main-top-navbar'>
    <div class="container">
      <div class="navigation-wrapper">
        <div class="left-col">
          <language-selector @langDropdownOpened="handleMobileMenuClose" />
        </div>
        <div class="middle-col">
          <router-link to="/">
            <img class="logo" src="/static/images/logos/eger-logo_colorfull.png">
          </router-link>
        </div>
        <div class="right-col">
          <language-selector />
          <button-cart />
          <user-dropdown />
          <icon-hamburger @click.native="handleHamburgerMenuClick" />
        </div>
      </div>
    </div>
    <mobile-menu-dropdown
      :isOpen="isMobileMenuOpen"
      :navigations="mobileMenuLinks"
      @closeDropdown="handleMobileMenuClose"
    />
  </div>
</template>

<script>
import LanguageSelector from '@/components/shared/elements/selectors/LanguageSelector';
import ButtonCart from '@/components/shared/elements/buttons/ButtonCart';
import UserDropdown from '@/components/shared/modules/users/UserDropdown';

export default {
  name: 'MainTopNavbar',
  props: {},
  components: {
    LanguageSelector,
    ButtonCart,
    UserDropdown,
    'mobile-menu-dropdown': () => import('@/components/shared/elements/partials/MobileMenuDropdown'),
    'icon-hamburger': () => import('@/components/shared/elements/icons/IconHamburger'),
  },
  data: () => ({
    isMobileMenuOpen: false,
  }),
  created() {},
  computed: {
    mobileMenuLinks() {
      return [];
    },
  },
  methods: {
    handleHamburgerMenuClick() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    handleMobileMenuClose() {
      this.isMobileMenuOpen = false;
    },
  },
};
</script>

<style lang='scss' scoped>
$navbarHeight: 88px;
$navbarHeightMobile: 77px;
$logoHeight: 50px;

#main-top-navbar {
  position: relative;
  z-index: z('navbar');

  @media screen and (max-width: $breakpointDownMd) {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: $globalBackgroundColor;
    z-index: z('mobile-navbar');
  }
}

#mobile-menu-dropdown {
  position: fixed;
  top: $navbarHeightMobile;
  left: 0;
  width: 100%;
}

.navigation-wrapper {
  display: grid;
  grid-template-columns: auto auto 1fr;
  height: $navbarHeight;
  align-items: center;

  @media screen and (max-width: $breakpointDownMd) {
    height: $navbarHeightMobile;
    grid-template-columns: 1fr auto 1fr;
  }
}

.logo {
  width: auto;
  height: $logoHeight;

  @media screen and (max-width: $breakpointDownLg) {
    width: 120px;
    height: auto;
  }

  @media screen and (max-width: $breakpointDownMd) {
    width: 135px;
  }
}

.navigations {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  @media screen and (max-width: $breakpointDownMd) {
    display: none;
  }
}

.left-col {
  #language-selector {
    display: none;

    @media screen and (max-width: $breakpointDownMd) {
      display: inline-block;
    }

    &::v-deep {
      .dropdown-list {
        left: 0;
        transform: none;
      }
    }
  }
}

.right-col {
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 55px;
  align-items: center;

  @media screen and (max-width: $breakpointDownLg) {
    padding-left: 15px;
  }

  @media screen and (max-width: $breakpointDownMd) {
    column-gap: 0;
  }

  #language-selector,
  .user-dropdown {
    @media screen and (max-width: $breakpointDownMd) {
      display: none;
    }
  }
}

.button-cart {
  @media screen and (max-width: $breakpointDownMd) {
    margin: 0 35px 0 auto;
  }

  @media screen and (max-width: $breakpointDownXXs) {
    margin-right: 10px;
  }
}

.icon-hamburger {
  display: none;
  cursor: pointer;

  @media screen and (max-width: $breakpointDownMd) {
    display: block;
  }
}
</style>
