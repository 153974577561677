import api from '@/api/invoiceData';
// import store from '@/store';

const updateInvoiceData = (context, { invoiceId, invoiceData }) => new Promise((resolve, reject) => {
  api.updateInvoiceData(invoiceId, invoiceData)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const addInvoiceData = (context, invoiceData) => new Promise((resolve, reject) => {
  api.addInvoiceData(invoiceData)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const saveSelectedInvoiceDataIndex = (context, index) => new Promise(((resolve) => {
  context.commit('SAVE_SELECTED_INVOICE_DATA_INDEX', index);
  resolve(true);
}));

const attachInvoiceData = (context, { invoiceId, invoiceData }) => new Promise((resolve, reject) => {
  api.attachInvoiceData(invoiceId, invoiceData)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export default {
  updateInvoiceData,
  addInvoiceData,
  saveSelectedInvoiceDataIndex,
  attachInvoiceData,
};
