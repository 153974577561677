<template>
  <div id="modal-header" class="row no-gutters">
    <div class="col">
      <p class="modal-title" :style="titleStyle">{{$t(titleText)}}</p>
    </div>

    <div class="col-12 pt-4" v-if="hasSeparator">
      <hr class="line" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalHeader',
  props: {
    titleText: {
      type: String,
      required: true,
    },
    titleStyle: {
      type: [Object, Array],
      required: false,
      default: () => ({
        fontFamily: 'Rubik',
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#3a3d40',
      }),
    },
    hasSeparator: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#modal-header {
  .line {
    height: 1px;
    background-color: #f0f0f0;
    box-sizing: border-box;
    margin: 0;
    border-style: initial;
  }
  .modal-title {
    margin: 36px 18px 16px 18px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    color: $globalFontColor;
  }
}
</style>
