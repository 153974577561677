<template>
  <div class='user-dropdown'
    @mouseover="isDropdownVisible = true" @mouseleave="isDropdownVisible = false">
    <div class="user-btn">
      <span class="icon-profile-wrapper">
        <icon-profile :color="'#242e61'" />
      </span>
      <span class="text">{{ indicatorText }}</span>
      <icon-arrow-down :color="'#000'" />
    </div>
    <dropdown-login v-if="!isUserLoggedIn" :width="267" :isVisible="isDropdownVisible" />
    <dropdown-list v-else :isVisible="isDropdownVisible" :width="165">
      <span class="user-row">
        <span class="icon-profile-wrapper">
          <icon-profile :color="'#242e61'" />
        </span>
        <span class="name">{{ userName }}</span>
      </span>
      <span v-for="(item, i) in loggedInDropdownNavigations" :key="i" class="nav-item"
        @click="handleLoggedInNavClick(item.key)">
        {{ item.text }}
      </span>
    </dropdown-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import store from '@/store';

import IconProfile from '@/components/shared/elements/icons/IconProfile';
import IconArrowDown from '@/components/shared/elements/icons/IconArrowDown';

export default {
  name: 'UserDropdown',
  props: {},
  components: {
    'dropdown-login': () => import('@/components/shared/elements/partials/DropdownLogin'),
    'dropdown-list': () => import('@/components/shared/elements/partials/DropdownList'),
    IconProfile,
    IconArrowDown,
  },
  data: () => ({
    isDropdownVisible: false,
  }),
  created() {},
  computed: {
    ...mapGetters({
      loggedInUserProfile: 'users/getLoggedInUserProfile',
    }),
    isUserLoggedIn() {
      return this.loggedInUserProfile !== undefined;
    },
    userName() {
      return this.loggedInUserProfile.lastName;
    },
    indicatorText() {
      return this.isUserLoggedIn ? this.userName : this.$t('navbar.userMenu.text');
    },
    loggedInDropdownNavigations() {
      return [
        {
          key: 'prevOrders',
          text: this.$t('navbar.userMenu.loggedInDropdown.previousOrders'),
        },
        {
          key: 'profile',
          text: this.$t('navbar.userMenu.loggedInDropdown.profile'),
        },
        {
          key: 'logout',
          text: this.$t('navbar.userMenu.loggedInDropdown.logout'),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      logout: 'users/logout',
    }),
    handleLoggedInNavClick(key) {
      switch (key) {
        case 'prevOrders':
          this.$router.push('/users/purchases');
          this.isDropdownVisible = false;
          break;
        case 'profile':
          this.$router.push('/users/profile');
          this.isDropdownVisible = false;
          break;
        case 'logout':
          this.logout().then(() => {
            store.commit('storeCartId', undefined);
            this.$router.go();
          },
          );
          this.isDropdownVisible = false;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$profileIconSize: 35.64px;

.user-dropdown {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: -15px;
}

.user-btn {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: $black;
  text-transform: uppercase;
  cursor: pointer;

  .text {
    display: inline-block;
    width: 112px;
    text-align: center;
  }
}

.icon-profile-wrapper {
  width: $profileIconSize;
  height: $profileIconSize;
  border-radius: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  margin-top: -5px;
}

.user-row {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: $black;
  text-transform: uppercase;
  padding: 0 22px;
  margin: 0 0 12px;

  .icon-profile-wrapper {
    margin: 0 12px 0 0;
  }

  .name {
    margin: 5px 0 0;
  }
}

.nav-item {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  transition: $transitionBase;
  padding: 0 12px 0 24px;
  margin: 0 0 7px;
  display: block;

  &:hover {
    color: $white;
    background-color: $primaryBlue;
  }

  &:last-child {
    margin: 17px 0 0;
    position: relative;

    &:before {
      content: '';
      width: calc(100% - (2 * 14px));
      height: 1px;
      background-color: $hrColor;
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
