import defaultState from './state';

const SAVE_SELECTED_DATE = (state, date) => {
  state.selectedDate = date;
};

const SAVE_CURRENT_CART = (state, cart) => {
  state.currentCart = cart;
};

export default {
  SAVE_SELECTED_DATE,
  SAVE_CURRENT_CART,
};
