import { fetchData, apiURL } from './api_helper';

const getServicesStatus = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/settings/serverStatus`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getTermsAndConditions = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/settings/termsAndConditions`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getPrivacyPolicy = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/settings/privacyPolicy`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getCountries = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/settings/countries`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getApiVersionNumber = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/settings/version`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getPurchaseDateRange = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/settings/purchaseDateRange`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getFacilitySectionVisibility = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/settings/facilitySectionVisibility`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

export default {
  getServicesStatus,
  getTermsAndConditions,
  getPrivacyPolicy,
  getCountries,
  getApiVersionNumber,
  getPurchaseDateRange,
  getFacilitySectionVisibility,
};
