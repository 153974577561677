<!-- eslint-disable -->
<template>
  <div class='icon-arrow-down'>
    <svg xmlns="http://www.w3.org/2000/svg" width="10.791" height="6.927" viewBox="0 0 10.791 6.927">
      <path id="Path_38099" data-name="Path 38099" d="M-.73-4.875a.432.432,0,0,0,0-.609l-1.112-1.1a.424.424,0,0,0-.6,0L-6-3.033-9.556-6.589a.424.424,0,0,0-.6,0l-1.112,1.1a.432.432,0,0,0,0,.609L-6.3.087a.424.424,0,0,0,.6,0Z" transform="translate(11.396 6.715)" :fill="color"/>
    </svg>
  </div>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'IconArrowDown',
  props: {
    color: {
      type: String,
      required: false,
      default: '#f7f7f7',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped></style>
