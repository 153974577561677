import { fetchData, apiURL } from './api_helper';

const getCikks = (cikkcsoportId, selectedDate) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cikk/${cikkcsoportId}/list`;

  fetchData(endpointURL, 'POST', selectedDate).then((result) => {
    resolve(result.data);
  }).catch((error) => {
    reject(error.response);
  });
});

export default {
  getCikks,
};
