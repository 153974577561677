import { fetchData, apiURL } from './api_helper';

const getCikkcsoports = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/cikkcsoport`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result.data);
  }).catch((error) => {
    reject(error.response);
  });
});

export default {
  getCikkcsoports,
};
