import Modal from '@/components/shared/elements/modals/Modal';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      modalComponent: undefined,
      showSuggester: false,
      isBlur: false,
    };
  },
  computed: {
    modalKey() {
      if (!this.modalComponent.bind.is.name) {
        return Math.floor(Math.random() * Math.floor(100000));
      }
      return `${this.modalComponent.bind.is.name}_${Math.floor(
        Math.random() * Math.floor(1000),
      )}`;
    },
  },
  methods: {
    registerModals() {
      this.$eventBus.onShowModal(this, (payload) => {
        this.modalComponent = payload;
      });
      this.$eventBus.onCloseModal(this, () => {
        this.isBlur = !this.isBlur;
        this.modalComponent = null;
      });
    },
  },
};
