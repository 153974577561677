import api from '@/api/settings';

const storeLangCode = (context, langCode) => {
  context.commit('storeLangCode', langCode, { root: true });
};

const getServicesStatus = (context) => new Promise((resolve, reject) => {
  api.getServicesStatus()
    .then((response) => {
      context.commit('setServicesStatus', response.data.isServerUp, { root: true });
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getTermsAndConditions = (context) => new Promise((resolve, reject) => {
  api.getTermsAndConditions()
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getPrivacyPolicy = (context) => new Promise((resolve, reject) => {
  api.getPrivacyPolicy()
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getCountries = (context) => new Promise((resolve, reject) => {
  api.getCountries()
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getApiVersionNumber = (context) => new Promise((resolve, reject) => {
  api.getApiVersionNumber()
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getPurchaseDateRange = (context) => new Promise((resolve, reject) => {
  api.getPurchaseDateRange()
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

const getFacilitySectionVisibility = (context) => new Promise((resolve, reject) => {
  api.getFacilitySectionVisibility()
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export default {
  storeLangCode,
  getServicesStatus,
  getTermsAndConditions,
  getPrivacyPolicy,
  getCountries,
  getApiVersionNumber,
  getPurchaseDateRange,
  getFacilitySectionVisibility,
};
