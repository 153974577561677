import { fetchData, apiURL } from './api_helper';

const register = (registerData) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/users/create`;

  fetchData(endpointURL, 'POST', registerData).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const login = (loginData) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/users/login/`;

  fetchData(endpointURL, 'POST', loginData).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getLoggedInUserProfile = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/users/me`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const requestPasswordReset = (email) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/users/generatePasswordReset`;

  fetchData(endpointURL, 'POST', email).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const resetPassword = (requestObj) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/users/resetPassword`;

  fetchData(endpointURL, 'POST', requestObj).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const updateUserProfile = (requestObj) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/users/update`;

  fetchData(endpointURL, 'PATCH', requestObj).then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const deleteUserProfile = () => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/users/me/delete`;

  fetchData(endpointURL, 'DELETE').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

const getPrevPurchases = (page) => new Promise((resolve, reject) => {
  const endpointURL = `${apiURL}/orders/myPurchases/${page}`;

  fetchData(endpointURL, 'GET').then((result) => {
    resolve(result);
  }).catch((error) => {
    reject(error.response);
  });
});

export default {
  register,
  login,
  getLoggedInUserProfile,
  requestPasswordReset,
  resetPassword,
  updateUserProfile,
  deleteUserProfile,
  getPrevPurchases,
};
