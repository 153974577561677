import api from '@/api/simple';

const checkSimpleResponse = (context, requestObj) => new Promise((resolve, reject) => {
  api.checkSimpleResponse(requestObj)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
});

const checkOrderStatus = (context, requestObj) => new Promise((resolve, reject) => {
  api.checkOrderStatus(requestObj)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
});


export default {
  checkSimpleResponse,
  checkOrderStatus,
};
