<template>
  <button class='button-cart' @click="handleBtnClick">
    <span class="cart-icon-wrapper">
      <span class="counter"><span class="number">{{ itemsNumber }}</span></span>
      <span><icon-cart /></span>
    </span>
    <span class="text">{{ $t('navbar.cart.text') }}</span>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

import IconCart from '@/components/shared/elements/icons/IconCart';

export default {
  name: 'ButtonCart',
  props: {},
  components: {
    IconCart,
  },
  data: () => ({
  }),
  created() {},
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
    }),
    itemsNumber() {
      if (this.getCurrentCart !== undefined && this.getCurrentCart.cartItems.length > 0) {
        const merged = [].concat.apply(...this.getCurrentCart.cartItems.map((obj) => obj.cikkList));
        return merged.map((item) => item.amount).reduce((prev, next) => prev + next);
      }
      return 0;
    },
  },
  methods: {
    handleBtnClick() {
      if (this.$route.name !== 'CartScreen') {
        this.$router.push('/cart');
      }
    },
  },
};
</script>

<style lang='scss' scoped>
$cartIconWidth: 21.68px;
$cartIconHeight: 22.41px;
$itemsCounterSize: 15px;

button {
  border: 0;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
}

.text {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;

  @media screen and (max-width: $breakpointDownMd) {
    display: none;
  }
}

.cart-icon-wrapper {
  position: relative;

  .icon-cart {
    margin: 0 8px 0 0;

    &::v-deep {
      svg {
        width: $cartIconWidth;
        height: $cartIconHeight;
      }
    }
  }

  .counter {
    position: absolute;
    top: -3px;
    right: 3px;
    border-radius: 100%;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    color: $white;
    background-color: $globalRedColor;
    width: $itemsCounterSize;
    height: $itemsCounterSize;
    padding: 0.5px 0 0 1px;

    @media screen and (max-width: $breakpointDownMd) {
      padding: 0 0 1px 1px;
    }

    .number {
      line-height: $itemsCounterSize;
      display: inline-block;
    }
  }
}
</style>
