import defaultState from './state';

const SAVE_USER_PROFILE = (state, userObj) => {
  defaultState.loggedInUserProfile = userObj;
};

const DESTROY = (state) => {
  Object.keys(defaultState).forEach((key) => {
    state[key] = defaultState[key];
  });
};

const SAVE_LOGIN_ERROR = (state, errorText) => {
  state.loginError = errorText;
};

export default {
  SAVE_USER_PROFILE,
  DESTROY,
  SAVE_LOGIN_ERROR,
};
