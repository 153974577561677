import Vue from 'vue';
import i18n from '@/lang/i18n';
import router from '@/router';
import store from '@/store';
import VueSmoothScroll from 'vue2-smooth-scroll';

import FACILITIES from '@/components/shared/enums/facilities';
import PAYMENT_ERRORS from '@/components/shared/enums/paymentErrors';
import EventBus from '@/components/shared/eventbus';

// icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare, faPinterestSquare, faInstagramSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { faSpinner, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import App from './App.vue';

// moment
const moment = require('moment');
require('moment/locale/hu');
require('moment/locale/de');
require('moment/locale/en-gb');
require('moment/locale/pl');
// not needded in egri websop
// require('moment/locale/ru');
// require('moment/locale/ro');
// require('moment/locale/uk');
// require('moment/locale/cs');
// require('moment/locale/sk');

library.add(faFacebookSquare, faPinterestSquare, faInstagramSquare, faYoutubeSquare, faSpinner, faInfoCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

Vue.use(VueSmoothScroll);
Vue.use(require('vue-moment'), {
  moment,
});

// enums
Vue.prototype.$FACILITIES = FACILITIES;
Vue.prototype.$PAYMENT_ERRORS = PAYMENT_ERRORS;

Vue.prototype.$eventBus = EventBus;

Vue.filter('toCurrency', (number) => {
  if (typeof number !== 'number') {
    return number;
  }
  const formatter = new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
    minimumFractionDigits: 0,
  });

  const parts = formatter.formatToParts(number);

  return parts.map(({ type, value }) => {
    switch (type) {
      case 'group':
        return '.';
      default:
        return value;
    }
  }).reduce((string, part) => string + part);
});

Vue.filter('toCurrencyWithoutSymbol', (number) => {
  if (typeof number !== 'number') {
    return number;
  }
  const formatter = new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: 'HUF',
    minimumFractionDigits: 0,
  });

  const parts = formatter.formatToParts(number);

  return parts.map(({ type, value }) => {
    switch (type) {
      case 'group':
        return '.';
      case 'currency':
        return '';
      default:
        return value;
    }
  }).reduce((string, part) => string + part);
});

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
